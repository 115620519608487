import * as React from "react";
import Layout from "../../Layout/MasterLayout/MasterLayout";
import CardValores from "../../components/CardValores/CardValores";
import CardConteudo from "../../components/CardConteudo/CardConteudo";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import imgMenina from "../../assets/images/contraturno/oficinas/oficinas.png";
import SEO from "../../components/SEO/SEO";

import '../../components/css/images.css'

export default function Oficinas() {
	let oficinas = [
		{
			title: "Futsal",
			horario: "segunda-feira, das 13h30 às 15h",
			description:
				"// A oficina de Futsal tem o intuito de promover o conhecimento dos fundamentos, das regras, das estratégias e da prática da modalidade. Assim, os estudantes terão a oportunidade de desenvolver o equilíbrio, a coordenação motora, a colaboração e o trabalho em equipe.",
			borderColor: "comercial",
		},
		{
			title: "Futsal (somente para o 1° e 2° Ciclos do Ensino Fundamental)",
			horario: "terça-feira, das 18h às 19h30",
			description:
				"// A oficina de Futsal tem, por meio de momentos de muita diversão, o intuito de promover o conhecimento dos fundamentos técnicos, das regras, das estratégias e da prática da modalidade. Assim, os estudantes terão a oportunidade de desenvolver diferentes aspectos motores, além de experenciar colaboração, trabalho em equipe, dribles, grandes defesas, harmonia de jogadas coletivas.",
			borderColor: "comercial",
		},
		{
			title: "Vôlei (4° Ciclo e Ensino Médio)",
			horario: "Quinta-feira, das 12h às 13h30",
			description:
				"// A oficina de Vôlei tem o objetivo de promover a interação e socialização dos estudantes por meio da prática esportiva. Além disso, desenvolver e aprimorar os fundamentos técnicos e compreender as regras e as táticas da modalidade, aplicando a colaboração e o trabalho em equipe.",
			borderColor: "comercial",
		},
		{
			title: "Grupo Fermata",
			horario: "segunda-feira, das 12h às 13h30",
			description:
				"// A presença da música na vida dos seres humanos é incontestável. Ela tem acompanhado a história da humanidade ao longo dos tempos, exercendo as mais diferentes funções. Está presente em todas as regiões do globo, em todas as culturas e em todas as épocas. Ou seja, a música é uma linguagem universal, que ultrapassa as barreiras do tempo e do espaço. A oficina tem o objetivo de musicalizar o estudante para percepções dos sentidos auditivos e auxiliar no desenvolvimento afetivo, cognitivo e emocional, de maneira divertida e envolvente.",
			borderColor: "comercial",
		},
		{
			title: "Gastronomia",
			horario: "terça-feira, das 13h30 às 17h",
			description:
				"// A oficina Gastronomia tem a intenção de despertar o interesse do estudante para a boa alimentação. Por meio do conhecimento e da manipulação dos alimentos, é estimulado o trabalho em equipe e o desenvolvimento de valores como a criatividade, a importância do planejamento, o senso de organização, a higiene, a disciplina e o espírito de liderança.",
			borderColor: "comercial",
		},
		{
			title: "Aventuras na Natureza",
			horario: "quarta-feira, das 13h30 às 15h",
			description:
				"// As Aventuras na Natureza objetivam despertar a sensibilidade do estudante em relação a seu corpo e ao ambiente bem como sua atitude e transformação diante da realidade que o rodeia. Por meio do contato e da reaproximação humana com a natureza, são proporcionadas atividades físicas de aventura na natureza (AFAN) e esportes radicais no intuito de fomentar atitudes e valores referentes ao meio ambiente e à qualidade de vida.",
			borderColor: "comercial",
		},
		{
			title: "Ateliê de Artes",
			horario: "quarta-feira, das 15h30 às 17h",
			description:
				"// O Ateliê das Artes objetiva proporcionar aos estudantes o contato com as mais primárias manifestações artísticas e expressivas, como a pintura em tela, a cerâmica e o desenho, para despertar a visão para a beleza, a simplicidade e suas apreciações. Sua proposta é desenvolver a autonomia a partir de vivências de expressividade artística, com liberdade para a escolha dos materiais e das técnicas que serão utilizadas nas suas produções.",
			borderColor: "comercial",
		},
		{
			title: "Teatro",
			horario: "quinta-feira, das 13h30 às 17h",
			description:
				"// A oficina Teatro pretende desenvolver, nos seus participantes, a expressão corporal na criação dramática com técnicas e modos de composição e de improvisação teatral. Por meio de dinâmicas dirigidas, busca a sensibilização, a desinibição, o desenvolvimento da capacidade criativa, a comunicação e expressão, o controle da ansiedade, a flexibilidade, a espontaneidade e a capacidade de raciocínio lógico.",
			borderColor: "comercial",
		},
		{
			title: "Atitude Faixa Preta",
			horario: "quinta-feira, das 15h30 às 17h",
			description:
				"// A Atitude Faixa Preta apresenta os valores presentes nas artes marciais que nortearão as dinâmicas e propostas desta oficina. Por meio do desenvolvimento da motricidade, da socialização e da cognição, preconiza práticas para o autocuidado e para a consciência coletiva.",
			borderColor: "comercial",
		},
		{
			title: "Oficina de Basquete",
			horario: "quinta-feira, das 17h às 18h",
			description:
				"// A Oficina Basquete Feevale busca valorizar a criação de hábitos para as atividades físicas. A partir de práticas que desenvolvem habilidades, técnicas, táticas e físicas, relacionadas ao basquete, promove a socialização, o autoconhecimento e o desenvolvimento de relações afetivas e cognitivas dos praticantes.",
			borderColor: "comercial",
		},
		{
			title: "Super Esportes",
			horario: "sexta-feira, das 13h30 às 15h",
			description:
				"// O Super Esportes busca valorizar a criação de hábitos para as atividades físicas. A partir de práticas que desenvolvem habilidades, técnicas, táticas e físicas, relacionadas às mais diversas modalidades esportivas, promove a socialização, o autoconhecimento e o desenvolvimento de relações afetivas e cognitivas dos praticantes.",
			borderColor: "comercial",
		},
		/* 
		{
			title: "Ritmos",
			horario: "sexta-feira, das 15h30 às 17h",
			description:
				"// A ginástica rítmica é um tipo de ginástica desenvolvida com movimentos corporais baseados nos elementos do balé e da dança teatral, numa mistura de arte, criatividade e capacidade física, cuja execução é realizada em sincronia com a música. Mesclando os movimentos da ginástica tradicional com a dança, surgiu a ginástica rítmica. Os elementos que envolvem a modalidade são os movimentos de mãos livres, sem utilização de aparelhos, e a utilização dos cinco materiais oficiais: arco, bola, corda, maças e fita.",
			borderColor: "comercial",
		},
		*/
	];

	return (
		<Layout>
			<SEO title="Oficinas" lang="pt-br" />
			<MDBContainer>
				<MDBRow>
					<MDBCol col={12} md={6} className="order-1 order-sm-0 my-auto">
						<h1 className="pageTitle fgsecondary-gradient pb-5">oficinas</h1>
						<h3 className="fgsecondary-gradient pb-3">nosso propósito</h3>
						<p className="text-left">
							As Oficinas são destinadas aos estudantes matriculados nas Etapas
							Finais do Ensino Fundamental (3° e 4° ciclos) e para o Ensino
							Médio. Elas ocorrem no período da tarde e proporcionam aos
							estudantes o desenvolvimento pessoal, social, cultural, afetivo,
							lúdico, criativo e físico, utilizando, para tanto, diferentes
							espaços de aprendizagem.
						</p>
						<p className="text-left">
							Nessa modalidade, os responsáveis e os estudantes podem escolher
							em quais oficinas desejam inscrever-se, de acordo com a etapa de
							aprendizagem e/ou idade, podendo participar de mais de uma oficina
							no mesmo turno.
						</p>
						<p className="text-left">
							Matrículas abertas a partir do dia 19/02/2024.
							<br />
							As oficinas iniciam em 04/03/2024.
						</p>
					</MDBCol>
					<MDBCol
						col={12}
						md={6}
						className="py-3 text-justify order-0 order-sm-1 imagemTopo"
					>
						<img src={imgMenina} className="img-fluid" />
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgsecondary-gradient">grade de atividades</h3>
					</MDBCol>
				</MDBRow>
				{oficinas ? (
					<>
						{oficinas.map((atividade, index) => (
							<CardConteudo
								key={index}
								title={atividade.title}
								horario={atividade.horario}
								description={atividade.description}
								borderColor={atividade.borderColor}
								img={atividade.image}
								imgLeft={atividade.imgLeft}
							/>
						))}
					</>
				) : (
					<p>Em breve novas oficinas.</p>
				)}

				<MDBRow className="pt-3">
					<MDBCol col={12} md={6}>
						<h3 className="fgsecondary-gradient">
							para estudantes da Escola de Aplicação Feevale
						</h3>
						<p className="text-left">
							Se você já estuda na Instituição, a matrícula das atividades
							extracurriculares é feita de forma on-line, com a possibilidade de
							matrícula para almoço.
						</p>
						<p className="text-left">
							Matrículas abertas! As atividades curriculares iniciam em 04/03/2024.
						</p>
						<p className="text-left">
							1º PASSO: <a target="blank" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&ps=144#/es/inscricoeswizard/dados-basicos">Clique aqui </a> para realizar sua inscrição.
						</p>
						<p className="text-left">
							2º PASSO: Após a inscrição, o responsável financeiro deverá acessar o <a target="blank" href="https://portal.feevale.br/FrameHTML/web/app/edu/portalEducacional/login/"> Portal do Aluno </a> para seguir com o processo de seleção e contratação das atividades por meio da Matrícula Online.
						</p>
						<p className="text-left">
							3º PASSO: A finalização acontece no Portal do Aluno e o contrato é assinado no mesmo lugar. Após a assinatura eletrônica do contrato, o estudante poderá participar das aulas matriculadas.
						</p>
						<p className="text-left">
							<b>Importante:</b> Os dados informados na inscrição passarão por validação e caso sejam identificadas inconsistências, a matrícula poderá ser cancelada e o responsável será comunicado. 
						</p>
					</MDBCol>
					<MDBCol col={12} md={6}>
						<h3 className="fgsecondary-gradient">para comunidade</h3>
						<p className="text-left">
							Estudantes de outras instituições que têm interesse em participar
							das atividades extracurriculares devem fazer sua matrícula
							presencialmente no Atendimento Feevale.
						</p>
						<p className="text-left">
							Matrículas abertas! As atividades curriculares iniciam em 04/03/2024. 
						</p>
						<p className="text-left">
							1º PASSO: Agendar com a coordenação pedagógica da escola via telefone 3584-7108.
						</p>
						<p className="text-left">
							2º PASSO: Após o bate-papo com a coordenação pedagógica <a target="blank" href="https://portal.feevale.br/FrameHTML/web/app/Edu/PortalProcessoSeletivo/?c=1&ps=145#/es/inscricoeswizard/dados-basicos"> clique aqui</a> Para efetivar a inscrição é necessário ter digitalizado os seguintes documentos:
							<ul style={{ listStyleType: "none" }}>
								<li>// uma foto 3x4 do estudante;</li>
								<li>// cópia da Certidão de Nascimento do estudante;</li>
								<li>
									// cópia da Carteira de Identidade (RG) do estudante e
									responsável;
								</li>
								<li>// cópia do CPF do responsável;</li>
								<li>
									// cópia do comprovante de residência com CEP (conta de luz ou
									telefone).
								</li>
							</ul>
						</p>
						<p className="text-left">
							3º PASSO: Após a inscrição e validação dos documentos enviados, o responsável receberá um e-mail com as orientações dos próximos passos para a matrícula. 
						</p>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<MDBCol>
						<h3 className="fgsecondary-gradient text-center pt-3 pb-2">
							valores*
						</h3>
					</MDBCol>
				</MDBRow>
				<MDBRow>
					<CardValores
						bgColor="bgsecondary-gradient"
						amount="Atividades ½ tarde"
						value="77,51"
						type={2}
					/>
					<CardValores
						bgColor="bgsecondary-gradient"
						amount="Atividade tarde inteira"
						value="100,88"
						type={2}
					/>
				</MDBRow>
				<MDBRow>
					<MDBCol className="pt-1">
						<p className="small font-italic">
							*Valores válidos para 2024. As matrículas contratadas em 2024 relativas ao ano de 2025 estão sujeitas ao reajuste de mensalidade conforme disposto na Lei nº 9.870 de 23/11/1999. Eventual diferença de valor será acrescida nas parcelas posteriores.
						</p>
						<br></br>
						<p>
							Para as contratações realizadas até o dia 15 do mês, será cobrado
							o valor integral da mensalidade, sendo que as contratações
							realizadas do dia 11 ao dia 15 serão cobradas no mês subsequente,
							com a mensalidade do mês seguinte. Para as contratações realizadas
							a partir do dia 16 do mês, o contratante pagará 50% do valor total
							da mensalidade do mês, com a mensalidade do mês seguinte. Para o
							restante dos meses, será cobrado o valor integral, conforme
							contratado.
						</p>
						<p>
							Para as contratações realizadas até o dia 10 de cada mês, os
							valores serão cobrados no respectivo mês.
						</p>
						<p>
							A realização das Oficinas está condicionada ao número mínimo de
							estudantes por turma.
						</p>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</Layout>
	);
}
